<template>
  <div class="mb-5">
    <vue-editor
      v-validate="{ rules: validationRules }"
      :data-vv-name="name"
      v-model="contentLocal"
      :editorToolbar="customToolbar"
      useCustomImageHandler
      @image-added="handleImageAdded"
      :customModules="customModulesForEditor"
      :editorOptions="editorSettings"
      :disabled="disabled"
    >
    </vue-editor>
    <span class="error--text" v-if="hasMessageError">{{ getMessageError }}</span>
  </div>
</template>

<script>
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import { VueEditor } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VueEditorImage',
  components: {
    VueEditor,
  },
  props: {
    name: {
      type: String,
    },
    content: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: String,
      default: 'required',
    },
  },
  data: () => ({
    customToolbar: [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['image'],
      ['video'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
    customModulesForEditor: [
      { alias: 'imageDrop', module: ImageDrop },
      { alias: 'imageResize', module: ImageResize },
    ],
    editorSettings: {
      modules: {
        imageDrop: true,
        imageResize: {},
      },
    },
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    hasMessageError() {
      const error = !!this.errors?.first(this.name);
      this.$emit('set-has-error-content', !error && this.content !== '', this.name);

      return error;
    },
    getMessageError() {
      return this.$t('globals.form.errors.required');
    },
    contentLocal: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit('set-content', value, this.name);
      },
    },
  },
  methods: {
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      await this[UPLOAD_FILE](file);

      Editor.insertEmbed(cursorLocation, 'image', this.file.url);
      resetUploader();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

::v-deep .ql-tooltip.ql-editing {
  white-space: normal;
}

::v-deep .ql-formats {
  max-height: 20px;
}
</style>
